import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter , OnDestroy } from '@angular/core';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service'
import { DataOrderService } from 'app/shared/services/data-order.service'
import { AlertService } from 'app/shared/services/alert/alert.service';
import { interval, Subscription } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-shift-button',
  templateUrl: './shift-button.component.html',
  styleUrls: ['./shift-button.component.scss']
})
export class ShiftButtonComponent implements OnInit {
  @Output() shiftStateChange = new EventEmitter<boolean>();
  @Output() shiftAvailabilityChange = new EventEmitter<string>();

  buttonText: string = '';
  isShiftAvailable: string = '';
  isCompleted: boolean = false;
  shiftId: number;
  currentShift: any;
  actualStartTime: moment.Moment | null = null; // Track actual shift start time
  isFirstTimeClick:undefined | string = undefined;


  constructor(
    public jwtAuth: JwtAuthService,
    private dataOrder: DataOrderService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.getShiftStatus();

  // Restore actual and scheduled start times from local storage
    const storedActualStartTime = localStorage.getItem(`shift_${this.shiftId}_actualStartTime`);
    const storedStartTime = localStorage.getItem(`shift_${this.shiftId}_startTime`);

    if (storedActualStartTime) {
      this.actualStartTime = moment(storedActualStartTime);
    }
    if (storedStartTime) {
      this.currentShift.startTime = moment(storedStartTime);
    }
  
    this.updateButtonState();
  
  }

  convertToLocalTime(utcTime: string): moment.Moment {
    return moment.utc(utcTime).local();
  }

  getShiftStatus() {
    this.dataOrder.getClinicianShiftStatus().subscribe((res) => {
      if (res) {
        this.shiftId = res.shiftId;
        this.currentShift = {
          startTime: this.convertToLocalTime(res.startTime),
          endTime: this.convertToLocalTime(res.endTime),
          status: res.shiftStatus,
        };

        this.updateButtonState();
        if (res.shiftStatus === 'no_shift') {
          this.isShiftAvailable = 'No upcoming shifts available';
          this.shiftAvailabilityChange.emit(this.isShiftAvailable);
        }
      }
    });
  }


  updateButtonState() {
    const now = moment();
    const { startTime, endTime, status } = this.currentShift;
  
    // Calculate 10 minutes before the shift start time
    const tenMinutesBeforeStart = moment(startTime).subtract(10, 'minutes');
  
    if (now.isBetween(startTime, endTime)) {
      // During the shift
      this.isShiftAvailable = ''; 
      this.shiftAvailabilityChange.emit(this.isShiftAvailable);
  
      switch (status) {
        case 'assigned':
          this.buttonText = 'Start';
          this.isCompleted = false;
          break;
        case 'started':
          this.buttonText = 'Pause';
          this.isCompleted = true;
          break;
        case 'paused':
          this.buttonText = 'Resume';
          this.isCompleted = true;
          break;
      }
    } else if (now.isBefore(startTime)) {
      // Before the shift
      const localStartTime = this.convertToLocalTime(startTime); // Convert UTC to local time
  
      if (now.isSameOrAfter(tenMinutesBeforeStart)) {
        // Within the 10-minute early start window
        this.isShiftAvailable = ''; 
        this.shiftAvailabilityChange.emit(this.isShiftAvailable);
  
        switch (status) {
          case 'assigned':
            this.buttonText = 'Start';
            this.isCompleted = false;
            break;
          case 'started':
              this.buttonText = 'Pause';
              this.isCompleted = true;
              break;
          case 'paused':
            this.buttonText = 'Resume';
            this.isCompleted = true;
            break;
        }
      } else {
        // Outside the 10-minute early start window
        this.isShiftAvailable = `Shift starts at ${localStartTime.format('h:mm A')}`;
        this.shiftAvailabilityChange.emit(this.isShiftAvailable);
  
        this.buttonText = ''; 
        this.isCompleted = false;
      }
    } else {
      // After the shift has ended
      this.isShiftAvailable = 'Shift has ended';
      this.shiftAvailabilityChange.emit(this.isShiftAvailable);
  
      this.buttonText = ''; 
      this.isCompleted = false;
    }
  }
  
  
  updateClinicianShiftStatus(status: string) {
    const data = {
      shiftId: this.shiftId,
      status,
      from : this.isFirstTimeClick
    };
    this.dataOrder.updateStatus(data).subscribe(
      (res) => {
        if (res.status === 200) {
          this.alertService.notifySuccess(res.msg);
          this.getShiftStatus();
        } else {
          this.alertService.notifyError(res.msg);
        }
      },
      (err) => {
        this.alertService.notifyError(err.error.msg);
      }
    );
  }

  changeShiftStatus() {
    const now = moment();
    if (this.buttonText === 'Start') {
       // Store actual start time and scheduled start time in local storage
      this.actualStartTime = now;
      localStorage.setItem(`shift_${this.shiftId}_actualStartTime`, now.toISOString());
      localStorage.setItem(`shift_${this.shiftId}_startTime`, this.currentShift.startTime.toISOString());
      this.isFirstTimeClick = undefined;
      this.updateClinicianShiftStatus('started');
    } else if (this.buttonText === 'Pause') {
      this.isFirstTimeClick = undefined;
      this.updateClinicianShiftStatus('paused');
    } else if (this.buttonText === 'Resume') {
      this.isFirstTimeClick = "paused"
      this.updateClinicianShiftStatus('started');
    }
  }


  onCompleteShift() {
    const storedActualStartTime = localStorage.getItem(`shift_${this.shiftId}_actualStartTime`);
    const storedStartTime = localStorage.getItem(`shift_${this.shiftId}_startTime`);
  
    if (storedActualStartTime && storedStartTime) {
      const actualStartTime = moment(storedActualStartTime);
      const scheduledStartTime = moment(storedStartTime);
      const effectiveStartTime = moment.max(actualStartTime, scheduledStartTime);
      const fifteenMinutesAfterStart = effectiveStartTime.add(15, 'minutes');
      const now = moment();
  
      if (now.isBefore(fifteenMinutesAfterStart)) {
        const remainingTime = fifteenMinutesAfterStart.diff(now, 'minutes');
        this.alertService.notifyError(`You cannot complete the shift yet. Please wait ${remainingTime + 1} more minutes.`);
        return;
      }
    }
  
    // Allow shift completion if eligible
    this.updateClinicianShiftStatus('completed');
    this.isCompleted = false;
    this.buttonText = '';
    localStorage.removeItem(`shift_${this.shiftId}_actualStartTime`);
    localStorage.removeItem(`shift_${this.shiftId}_startTime`);
  }
  


}
