<div class="table-container">
    <app-promotion-banner></app-promotion-banner>
    <ng-container *ngIf="!isMobile; else mobileView">
        <div class="row">
            <ng-container *ngIf="!jwtAuth.isTelehealthPharmacyUser() && !jwtAuth.isPortalUser() && !jwtAuth.isDoctor()">
                <button mat-icon-button (click)="refreshPage()" matTooltip="Refresh Order Count" class="refresh-button">
                    <mat-icon>refresh</mat-icon>
                </button>
                <mat-label *ngIf="filters.length !== 0">Filters</mat-label>
                <div>
                    <ng-container *ngTemplateOutlet="filterButtons"></ng-container>
                </div>
            </ng-container>

            <span fxFlex></span>
            <ng-container *ngTemplateOutlet="searchInput"></ng-container>
        </div>
    </ng-container>
    <ng-template #mobileView>
        <div class="mobile-order-controls">
            <div class="mobile-top-row">
                <ng-container *ngIf="!jwtAuth.isTelehealthPharmacyUser() && !jwtAuth.isPortalUser()">
                    <button mat-icon-button (click)="refreshPage()" matTooltip="Refresh Order Count"
                        class="refresh-button">
                        <mat-icon>refresh</mat-icon>
                    </button>
                    <mat-label *ngIf="filters.length !== 0">Filters</mat-label>
                </ng-container>
                <div class="search-part">
                    <ng-container *ngTemplateOutlet="searchInput"></ng-container>
                </div>
            </div>
            <div class="mobile-filter-buttons">
                <ng-container *ngTemplateOutlet="filterButtons"></ng-container>
            </div>
        </div>
    </ng-template>

    <ng-template #filterButtons>
        <button *ngFor="let filter of filters" mat-stroked-button class="filter-button" [ngClass]="{
            'active-filter-button': filterBox === filter
        }" (click)="selectFilter(filter)">
            {{ filter === 'Waiting For Review' ? 'Quote Request' : filter === 'Awaiting Authorisation' ? 'Awaiting
            Payment' : filter }}
        </button>
    </ng-template>

    <ng-template #searchInput>
        <mat-form-field [ngStyle]="{margin: '0 24px'}" *ngIf="!isDriver && !jwtAuth.isDoctor()">
            <mat-label>Search</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="search" #input>
        </mat-form-field>
    </ng-template>

    <mat-card *ngIf="isMobile ? !isDriver:true">
        <div class="mat-elevation-z0 table-inner">
            <div style="overflow-x: auto;">
                <table class="table-class orders-table" #table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox #selectAll (change)="$event ? toggleAllRows() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                [aria-label]="checkboxLabel()">
                            </mat-checkbox>
                        </th>

                        <td mat-cell *matCellDef="let row; let i = index">
                            <mat-checkbox (click)="$event.stopPropagation();" (change)="$event ? toggleRow(row) : null"
                                [checked]="selection.isSelected(row)" [disabled]="isRowDisabled(row , i)"
                                [aria-label]="checkboxLabel(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="createddate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Received</th>
                        <td mat-cell *matCellDef="let element" class="row-class">
                            <span class="mobile-label"></span> {{element.created_At| date: 'dd/MM/yyyy h:mm:ss a' }}
                            <div class="mt-2">{{ element.totalDiff }}</div>
                        </td>

                    </ng-container>

                    <ng-container matColumnDef="source">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Source</th>
                        <td mat-cell *matCellDef="let element" class="row-class">
                            <span *ngIf="admin==='1' || jwtAuth.isBusiness()"
                                [ngStyle]="{color:element.locationstatus===1?'#6ABD35': element.locationstatus===2?'#FF7901':'black'}">{{element.locationname}}<br></span>
                            <span [ngStyle]="{color:'black'}">{{element.source}}</span>
                        </td>

                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{jwtAuth.isDoctor() ? 'Patient Name' : 'Customer Name'}}
                        </th>
                        <td mat-cell *matCellDef="let element" class="row-class"><span class="mobile-label"></span>
                            {{element.firstName}} {{element.lastName}}
                            <!-- <mat-icon 
                                *ngIf="admin === '1'"
                                class="icon--sm" 
                                [ngClass]="element.userVerification.color" 
                                [svgIcon]="element.userVerification.icon" 
                                [matTooltip]="element.userVerification.displayName"
                                matTooltipHideDelay="800"
                            ></mat-icon> -->
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="orderid">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{jwtAuth.isDoctor() ? 'Patient Details'
                            :'Order Details'}} </th>
                        <td mat-cell *matCellDef="let element" class="row-class">
                            <span class="mobile-label"></span>
                            <div>{{element.uniqueOrderId}}</div>
                            <div class="mt-2">{{element.non_Script}}&times; Items {{element.script}}&times; Script</div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="orderDetails">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Order Details </th>
                        <td mat-cell *matCellDef="let element" class="row-class">
                            <span class="mobile-label"></span>
                            <div>{{element.uniqueOrderId}}</div>
                            <div class="mt-2">{{element.non_Script}}&times; Items {{element.script}}&times; Script</div>
                            <div class="mt-2">{{element.firstName}} {{element.lastName}}</div>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="totalStatus">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{isDriver?'Payment Status': 'Total' }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngIf="length!=0">
                                <span class="mobile-label"></span>
                                <div *ngIf="!isDriver">{{element.orderTotal | currency}}</div>
                                <div class="mt-2" [class]="{'mm-text-pink':element.paymentStatus==='0'}">{{
                                    getPaymentStatus(element.source, element.paymentStatus) }}</div>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="ordertype">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
                        <td mat-cell *matCellDef="let element; let i = index;" (click)="$event.stopPropagation()">
                            <div #doorDashiFrameTriggers [id]="'dd-iframe-trigger' + i" [ngClass]="
                          {
                              'mm-text-pink': element.delivery_Tracking != null,
                              'has-link': element.delivery_Tracking != null
                          }" (click)="openDeliveryTracking(element.delivery_Tracking)">
                                {{getdeliveryType(element.orderType,element.deliveryPartnerName)}}
                            </div>
                            <!-- <div #doorDashiFrames [id]="'dd-iframe-' + i" *ngIf="element.showDeliveryTracking" class="iframe">
                        <iframe width="100%" height="100%" src="https://www.doordash.com/drive/portal/track/5a7e78de-ed92-4c55-8c02-89ced60ced14" frameborder="0"></iframe>
                      </div> -->
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="important">
                        <th mat-header-cell *matHeaderCellDef>
                            <span>Important</span>
                            <mat-icon class="mm-ml-2" inline>warning</mat-icon>
                        </th>
                        <td mat-cell *matCellDef="let element; let i = index;">
                            <ul>
                                <li [ngClass]="{'mm-fw-700': item.bold}"
                                    *ngFor="let item of element.importantSurveyData" [innerHtml]="item.label"></li>
                            </ul>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="orderstatus">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="mm-my-3">
                                <span [ngStyle]="{color:element.orderStatus === 'New' ? '#0081ff' : '#ff8a48'}">
                                    {{ element.orderStatus === 'WaitingFor Review' ? 'Quote Request' :
                                    element.orderStatus
                                    === 'Awaiting Authorisation' ? 'Awaiting Payment': element.orderStatus }}
                                    <br>
                                    <span [ngStyle]="{color:'black'}">{{element.timeDif}}</span>
                                    <br>
                                </span>
                                <div class="mm-mt-3 d-flex justify-content-center" *ngIf="element.statusCodeName">
                                    <mat-chip-list>
                                        <mat-chip (click)="goToOrder(element.orderId, element)"
                                            class="mm-order-status-chip">{{element.statusCodeName}}</mat-chip>
                                    </mat-chip-list>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="clinician">
                        <th mat-header-cell *matHeaderCellDef> Clinician </th>
                        <td mat-cell *matCellDef="let element; let i=index">
                            <mat-form-field appearance="outline">
                                <mat-label>Clinician</mat-label>
                                <mat-select [(ngModel)]="element.selectedClinician"
                                    (selectionChange)="onClinicianChange($event.value, element.orderId)">
                                    <mat-option *ngFor="let clinician of element.availableClinicians"
                                        [value]="clinician">
                                        {{ clinician.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="owner">
                        <th mat-header-cell *matHeaderCellDef> Owner </th>
                        <td mat-cell *matCellDef="let element; let i=index" (dblclick)="showEdit(element, i)"
                            (change)="changeOwner(element, i)">
                            <span *ngIf="!element?.changeOwner"
                                class="mobile-label">{{unescapeNewlineChars(element.currentOwner)}}</span>
                            <textarea (blur)="hideEdit(element)" [(ngModel)]="element.currentOwner" autofocus matInput
                                *ngIf="element.changeOwner===true" class="mobile-label inline-edit"></textarea>
                        </td>
                    </ng-container>

                    <!-- <ng-container *ngIf="inline===true" matColumnDef="owner">
                  <th mat-header-cell *matHeaderCellDef> Owner </th>
                  <td mat-cell *matCellDef="let element" (change)="changeOwner()">
              </ng-container> -->



                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef> Action </th>
                        <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()" class="action-row">
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <span class="filler"
                                    *ngIf="_actions(element).length === 0 && hasNoActions(); else moreButton"></span>
                                <ng-template #moreButton>
                                    <button *ngIf="!jwtAuth.isDriver() && !jwtAuth.isDoctor()" mat-icon-button
                                        [matMenuTriggerFor]="menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button *ngFor="let action of _actions(element)"
                                            (click)="onQuickAction(element, action)" mat-menu-item>{{
                                            actionAlias(action,
                                            element.orderStatus) }}</button>
                                    </mat-menu>
                                </ng-template>
                                <span class="mm-text-accent" (click)="goToOrder(element.orderId , element)">{{
                                    getActionText(jwtAuth.isDoctor(), element.orderStatus)}}

                                </span>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; "></tr>
                    <tr mat-row *matRowDef="let row; let i=index; columns: displayedColumns;"
                        [ngClass]="getRowClass(row, i)" [class.no-filler]="hasNoActions()" class="example-element-row "
                        [class.example-expanded-row]="expandedElement===element " (click)="expandedElement=expandedElement===element
                      ? null : element " [ngStyle]="{'background-color': row.rowBackgroundColor}">
                    </tr>

                </table>
            </div>

            <mat-toolbar #paginator class="custom-paginator" *ngIf=" length >= 0">
                <span class="grow">Page {{pageIndex}} of {{lastPage}}</span>
                <div class="grow">
                    <div *ngIf="length==0">No Orders To Be Displayed</div>
                    <span class="grow">{{ from }} - {{ to }} of {{ length }} Results</span>
                    <span class="mobile-label"></span>
                </div>
                <span class="custom-paginator_options-hold">
                    <mat-form-field appearance="standard" style="width: 75px">
                        <mat-select (selectionChange)="pageSizeChange($event)" [(value)]="pageSize">
                            <mat-option *ngFor="let option of [50, 100, 500, 1000]" [value]="option">{{ option
                                }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <span>
                        <button disableRipple mat-icon-button [disabled]="pageIndex <= 1" (click)="getFirstPage()">
                            <mat-icon>first_page</mat-icon>
                        </button>
                        <button disableRipple mat-icon-button [disabled]="pageIndex <= 1" (click)="getPreviousPage()">
                            <mat-icon>chevron_left</mat-icon>
                        </button>
                        <button disableRipple mat-icon-button [disabled]="this.to >= length" (click)="getNextPage()">
                            <mat-icon>chevron_right</mat-icon>
                        </button>
                        <button disableRipple mat-icon-button [disabled]="this.to >= length" (click)="getLastPage()">
                            <mat-icon>last_page</mat-icon>
                        </button>
                    </span>
                </span>
            </mat-toolbar>
        </div>
    </mat-card>


</div>

<ng-container *ngIf="isMobile && isDriver">
    <div class="cards-container">
        <div class="card-container" *ngFor="let item of dataSource?.data">
            <div class="card-header">
                <div class="text-center">
                    <span [ngStyle]="{color:item.orderStatus==='New'?'#0081ff':'#ff8a48'}">{{item.orderStatus}}</span>
                    <p style="margin-bottom:0;">{{item.timeDif}}</p>
                </div>
                <div>ID : {{item.uniqueOrderId}}</div>
            </div>

            <div class="card-body">
                <div>
                    <p>
                        <span class="card-label">NAME: </span>{{item.firstName + ' ' + item.lastName}}
                    </p>
                    <p (click)="openGoogleMaps(item.latitude,item.longitude)"> <span class="card-label">ADDRESS: </span>
                        <span>{{item.addr1 ?item.addr1 +', ': '' }}</span>
                        <span>{{item.addr2?item.addr2+', ': '' }}</span>
                        <span>{{item.addsuburb?item.addsuburb + ', ':''}}
                            {{item.addstate?item.addstate + ', ' :
                            ''}}
                            {{item.addpostcode || ''}}<mat-icon color="accent" class="mr-4">location_on</mat-icon>
                        </span>

                    <p>
                        <span class="card-label">TYPE: </span><span [ngClass]="
                    {
                        'mm-text-pink': item.delivery_Tracking != null,
                        'has-link': item.delivery_Tracking != null
                    }" (click)="openDeliveryTracking(item.delivery_Tracking)">{{item.deliveryPartnerName}}</span>
                    </p>
                    <p style="margin-bottom:0;">
                        <span class="card-label">DELIVERY INSTRUCTIONS:</span> {{item.delivery_instructions}}
                    </p>
                </div>
            </div>

            <div class="card-footer" *ngIf=" item.orderStatus!=='Awaiting Delivery'">
                <a [href]="'tel:' +item.mobile" class="cta call-button">
                    Call
                </a>
                <div class="cta return-button" (click)="onQuickAction(item, 'Return')">
                    <p class="cta-text">Return</p>
                </div>
                <div class="completed-button" (click)="onQuickAction(item, 'Completed')">
                    <p class="cta-text">Delivered</p>
                </div>
            </div>

            <div *ngIf="item.orderStatus==='Awaiting Delivery'" class="card-footer-2"
                (click)="onQuickAction(item, 'In Transit')">
                <p class="cta-text">Collected</p>
            </div>
        </div>
    </div>
</ng-container>


<mat-spinner *ngIf="loading" diameter="50"></mat-spinner>