<mat-card *ngIf="serviceScript" class="view-orders-container">
    <mat-tab-group [(selectedIndex)]="tabIndex" disableRipple dynamicHeight>
        <mat-tab label="Order Details">
            <div class="example-large-box mat-elevation-z0">
                <mat-card class="mm-outlined order-details-inner">
                    <app-order-progress-bar [currentStatus]="currentStatus"
                        [paymentStatus]="serviceScript?.orderData.paymentstatus"
                        [orderClass]="serviceScript?.orderData.order_class"></app-order-progress-bar>
                    <mat-card fxLayout="row" fxLayoutAlign="space-between center">
                        <div>
                            <div class="current-status" fxLayout="row" fxLayoutAlign="start center">
                                <span>Current Status</span>
                                <mat-icon class="current-status-arrow">arrow_forward_ios</mat-icon>
                                <span class="mm-text-accent">{{ serviceScript?.orderData.orderstatus=='Waiting For
                                    Review'? 'Quote Request': serviceScript?.orderData.orderstatus=='Awaiting
                                    Authorisation'? 'Awaiting Payment': serviceScript?.orderData.orderstatus }}</span>
                            </div>
                            <div class="uniqueorderid">#{{ serviceScript?.orderData.uniqueorderid }}</div>
                        </div>
                        <div *ngIf="viewButtons()" class="d-flex flex-wrap mm-gap-5 mm-ml-5">
                            <button mat-flat-button
                                *ngIf="showMoreActionsButton && !isDriverUser && !jwtAuth.isDoctor()"
                                class="mm-bg-grey-blue mm-text-white mm-mr-2"
                                (click)="$event.stopPropagation();moreActions();">
                                <span>More</span>
                                <mat-icon class="mm-mb-1">keyboard_arrow_down</mat-icon>
                            </button>
                            <a mat-flat-button color="accent" *ngIf="jwtAuth.isAdmin() || !jwtAuth.isDoctor()"
                                class="mm-text-white mm-mr-2" [href]="'tel:' + serviceScript.orderData.mobilenumber">
                                <mat-icon class="mm-mr-1">call</mat-icon>
                                <span>Call Patient</span>
                            </a>

                            <a mat-flat-button color="primary" *ngIf="jwtAuth.isDoctor()" class="mm-text-white mm-mr-2"
                                (click)="outOfScopeDialog()">
                                <span>Escalate</span>
                            </a>

                            <button mat-flat-button color="accent" *ngIf="!isDriverUser && canShowPrintOrderButton()"
                                class="mm-text-white mm-mr-2" (click)="printOrder()">
                                <mat-icon class="mm-mr-1">print</mat-icon>
                                <span>Print Order</span>
                            </button>
                            <ul *ngIf="showMoreActions" class="vieworder-menu">
                                <li *ngFor="let menuItem of menuItems"
                                    [ngClass]="{'vieworder-sub-menu-trigger': menuItem.name === 'Change Status'}">
                                    <ng-container *ngIf="menuItem.name !== 'Change Status'; else hasSubMenu">
                                        <button mat-menu-item (click)="menuItemAction(menuItem.name)"
                                            [style]="{'color': menuItem.color}">{{ menuItem.name }}</button>
                                    </ng-container>
                                    <ng-template #hasSubMenu>
                                        <ng-container *ngIf="menuItem.sub.length > 0">
                                            <button mat-menu-item>
                                                <span>{{ menuItem.name }}</span>
                                                <mat-icon style="color: rgb(41, 41, 41)">keyboard_arrow_right</mat-icon>
                                            </button>
                                            <ul class="vieworder-menu vieworder-sub-menu">
                                                <li *ngFor="let subMenu of menuItem.sub">
                                                    <button mat-menu-item
                                                        (click)="$event.stopPropagation();changeStatus(subMenu);showMoreActions = false;">{{
                                                        subMenu=='Waiting For Review'? 'Quote Request':
                                                        subMenu=='Awaiting Authorisation'? 'Awaiting Payment': subMenu
                                                        }}</button>
                                                </li>
                                            </ul>
                                        </ng-container>
                                    </ng-template>
                                </li>
                            </ul>
                            <button
                                *ngIf="CanShowRepeatOrderButton &&currentStatus==='Completed'&&!isDriverUser && !jwtAuth.isDoctor()"
                                mat-flat-button class="mm-bg-orange mm-text-white mm-mr-2"
                                (click)="showReorderScriptDialog(reorderScripts,true)">Repeat Order</button>
                            <button *ngIf="showPrintLabelButton()" mat-flat-button
                                class="mm-bg-accent mm-text-white mm-mr-2" (click)=" printOrderLabel()">Print
                                Label</button>
                            <button *ngIf="showTopButton2() && !jwtAuth.isDoctor()" mat-flat-button
                                class="mm-bg-orange mm-text-white mm-mr-2" (click)="editOrder()">Edit Order</button>
                            <button *ngIf="showRejectButton() && !jwtAuth.isDoctor()" mat-flat-button
                                class="mm-bg-orange mm-text-white mm-mr-2"
                                (click)="changeStatus('Reject')">Reject</button>
                            <button *ngIf="currentStatus=='In Transit'&&isDriverUser && !jwtAuth.isDoctor()"
                                mat-flat-button class="mm-bg-orange mm-text-white mm-mr-2"
                                (click)="changeStatus('Return')">Return</button>
                            <button *ngIf="showTopButton3()" [matTooltip]="getProceedAction()" mat-flat-button
                                class="mm-bg-green mm-text-white" (click)="proceed()"
                                [disabled]="editshow === 1">{{getTopButtonText3()}}</button>
                            <button mat-flat-button *ngIf="showAddPaymentMethodButton()"
                                class="mm-bg-green mm-text-white" (click)="confirmManualPayment()">Add
                                Payment Method</button>
                            <button *ngIf="jwtAuth.isDoctor()" mat-flat-button class="mm-bg-primary mm-text-white"
                                (click)="completeAppointment()">Complete</button>

                        </div>
                        <button *ngIf="showOnlyProceedButton()" matTooltip="Completed" mat-flat-button
                            class="mm-bg-green mm-text-white" (click)="changeStatus('Completed')">Proceed</button>
                    </mat-card>
                    <div>
                        <mat-card class="view-items">
                            <div [ngClass]="isDriverUser?'mm-flex':'mm-grid'">
                                <div class="tile-hold" *ngFor="let viewItem of viewItems"
                                    [ngClass]="getTileClasses(viewItem)" [ngStyle]="getTileStyles(viewItem)">
                                    <ng-container *ngIf="viewItem.orderDetails && !isDriverUser">
                                        <div id="intersection-trigger">
                                            <h1 class="mm-text-accent">{{ viewItem.text }}</h1>
                                            <p><b>Date:</b> {{viewItem.orderDetails.date | date: 'dd/MM/yyyy h:mm a'}}
                                            </p>
                                            <p><b>From:</b> {{ viewItem.orderDetails.from }}</p>
                                            <p *ngIf="subscription"><b>Subscription:</b> <span
                                                    class="mm-ml-1 mm-text-orange mm-fw-500">{{ subscription.name
                                                    }}</span>
                                            </p>
                                            <p *ngIf="subscription"><b>Subscription Cycle:</b> <span
                                                    class="mm-ml-1 mm-fw-500">{{ subscription.cycle_number }}</span></p>
                                        </div>
                                        <div class="mb-6">
                                            <p>
                                                <span class="mm-text-accent mm-text-bold">Promo Code:&nbsp;</span>
                                                <span *ngIf="promoCode">{{promoCode}}</span>
                                                <span *ngIf="promoCode==null||promoCode==''" class="mm-text-pink">No
                                                    promotion code applied</span>
                                            </p>
                                        </div>
                                        <div class="mb-6" *ngIf="serviceScript.orderData.redemption_amount > 0">
                                            <p>
                                                <span class="mm-text-accent mm-text-bold">Redemption
                                                    Amount:&nbsp;</span>
                                                <span>${{serviceScript.orderData.redemption_amount || '0.00'}}</span>
                                            </p>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="viewItem.customerDetails">
                                        <div>
                                            <h1 class="mm-text-accent">{{viewItem.text}}</h1>
                                            <p>{{viewItem.customerDetails.firstName}}
                                                {{viewItem.customerDetails.lastName}}
                                            </p>
                                            <p *ngIf="!jwtAuth.isDoctor()">{{viewItem.customerDetails.email}}</p>
                                            <p *ngIf="!jwtAuth.isDoctor()">{{viewItem.customerDetails.mobilenumber}}</p>
                                            <p>{{viewItem.customerDetails.addressLine1 || ''}}</p>
                                            <p>{{viewItem.customerDetails.addressLine2 || ''}}</p>
                                            <p>{{viewItem.customerDetails.suburb?viewItem.customerDetails.suburb + '
                                                ,':''}}
                                                {{viewItem.customerDetails.state?viewItem.customerDetails.state + '
                                                ,' :
                                                ''}}
                                                {{viewItem.customerDetails.postcode || ''}}</p>
                                            <p *ngIf="serviceScript.orderData.phoneCode && !jwtAuth.isDoctor()">
                                                <b>Uber Code:&nbsp;</b>
                                                <span>{{serviceScript.orderData.phoneCode}}</span>
                                            </p>
                                            <p *ngIf="serviceScript.orderData.MembershipNumber && !jwtAuth.isDoctor()">
                                                <span class="mm-text-accent">Member No.&nbsp;</span>
                                                <span>{{serviceScript.orderData.MembershipNumber}}</span>
                                            </p>
                                            <p>
                                                <span *ngIf="!jwtAuth.isDoctor()"
                                                    class="mm-text-accent  mm-text-bold">Type:&nbsp;</span>
                                                <!-- <span *ngIf="serviceScript.orderData.ordertype==='Pick Up' || serviceScript.orderData.ordertype==='Pickup'">Pick Up</span> -->
                                                <span
                                                    *ngIf="serviceScript.orderData.deliverypartnername">{{serviceScript.orderData.deliverypartnername}}</span>
                                            </p>
                                            <p *ngIf="serviceScript.orderData.ordertype=='Delivery'">
                                                <span class="mm-text-accent  mm-text-bold">Delivery
                                                    Instructions:&nbsp;</span>
                                                <span>{{d_i}}</span>
                                            </p>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="viewItem.senderDetails">
                                        <div>
                                            <h1 class="mm-text-accent"><span>{{viewItem.text}}</span><span
                                                    class="mm-ml-2" [ngClass]="{
                                            'mm-text-green':serviceScript.orderData.locationstatus == 1,
                                            'mm-text-pink': serviceScript.orderData.locationstatus == 2
                                        }" *ngIf="admin === '1'">({{
                                                    getPartnerStatus(serviceScript.orderData.locationstatus) }})</span>
                                            </h1>
                                            <p>{{viewItem.senderDetails.locationname}}</p>
                                            <p>{{viewItem.senderDetails.phone}}</p>
                                            <p>{{viewItem.senderDetails.address}}</p>
                                            <p class="mb-3">{{viewItem.senderDetails.times}}</p>
                                        </div>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="viewItem.pharmInstruction && !isDriverUser && !jwtAuth.isDoctor()">
                                        <div>
                                            <h1 class="mm-text-accent">{{viewItem.text}}</h1>
                                            <!-- <p>{{viewItem.pharmInstruction.pickupinstructions}}</p> -->
                                            <div class="mb-6">
                                                <p style="font-weight:200;">
                                                    {{serviceScript.orderData.speakwithpharmacist==1?'Customer needs to
                                                    speak with pharmacist':'Customer does not need to speak with
                                                    pharmacist'}}
                                                </p>
                                            </div>
                                            <div class="mb-6">
                                                <p class="mm-text-accent">Allergies</p>
                                                <p class="mm-text-pink mat-body-2">{{
                                                    viewItem.pharmInstruction.anyallergies?viewItem.pharmInstruction.anyallergies:'No
                                                    allergies'}}</p>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="viewItem.pharmacyOrderNotes && admin === '1' && !isDriverUser">
                                        <h1 class="mm-text-accent">{{ viewItem.text }}</h1>
                                        <div class="pharmacy-notes-hold">
                                            <textarea
                                                [placeholder]="'Interact with ' + serviceScript.orderData.locationname"
                                                (focus)="showConfirmPharmacyNotes = true"
                                                (blur)="showConfirmPharmacyNotes = false" spellcheck="false"
                                                [formControl]="pharmacyOrderNotes"
                                                class="pharmacy-notes-input custom-scroll-bar">
                                        </textarea>
                                            <div class="confirm-notes-hold pharmacy-notes"
                                                *ngIf="showConfirmPharmacyNotes == true">
                                                <button class="confirm-note-button"
                                                    (mousedown)="$event.stopPropagation();_updateOrderNotes('pharmacyOrderNotes')"><mat-icon>check</mat-icon></button>
                                                <button class="confirm-note-button mm-ml-2"
                                                    (mousedown)="cancelUpdateOrderNotes('pharmacyOrderNotes')"><mat-icon>close</mat-icon></button>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="viewItem.additionalDeliveryInstructions && !isDriverUser && !jwtAuth.isDoctor()">
                                        <div class="order-notes-container" id="additionalDeliveryInstructions">
                                            <h1 class="mm-text-accent">{{ viewItem.text }}</h1>
                                            <div class="mb-6">
                                                <div *ngIf="viewItem.additionalDeliveryInstructions"
                                                    class="order-notes">{{
                                                    viewItem.additionalDeliveryInstructions }}</div>
                                            </div>
                                            <button *ngIf='canShowEditDeliveryInstructionsButton()' mat-flat-button
                                                class="mm-bg-grey-blue mm-text-white mm-mr-2"
                                                (click)="openDeliveryDetailsDialog()">
                                                Edit Delivery Instructions</button>

                                        </div>
                                    </ng-container>


                                    <ng-container *ngIf="viewItem.orderInstructions && !isDriverUser">
                                        <div class="order-notes-container">
                                            <h1 class="mm-text-accent">{{ viewItem.text }}</h1>
                                            <div class="order-notes-inner custom-scroll-bar">
                                                <div class="order-notes">
                                                    {{unescapeNewlineChars(viewItem.orderInstructions.orderInstructions)}}
                                                </div>
                                                <!-- <div class="more-notes-overlay"></div> -->
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="viewItem.orderRemarks?.show && !isDriverUser">
                                        <div class="order-notes-container">
                                            <h1 class="mm-text-accent">{{ viewItem.text }}</h1>
                                            <div class="order-notes-inner no-scroll-bar">
                                                <div class="order-notes">
                                                    {{viewItem.orderRemarks.remarks}}
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="viewItem.orderRemarks?.show && !isDriverUser">
                                        <div class="order-notes-container">
                                            <h1 class="mm-text-accent">{{ viewItem.text }}</h1>
                                            <div class="order-notes-inner no-scroll-bar">
                                                <div class="order-notes">{{viewItem.orderRemarks.remarks}}
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="notes-hold">
                                <div *ngIf="admin === '1'" class="pharmacy-instructions">
                                    <div fxLayout="column" fxLayoutAlign="start center" class="inner"
                                        [ngClass]="{'editing': showConfirmCurrentOwner}">
                                        <div class="notes-inner-container" fxLayout="column">
                                            <div class="notes-header">
                                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                                    <span>Current Owner</span>
                                                    <div *ngIf="showConfirmCurrentOwner == true">
                                                        <button class="confirm-note-button"
                                                            (mousedown)="_updateOrderNotes('currentOwner');"><mat-icon>check</mat-icon></button>
                                                        <button class="confirm-note-button mm-ml-2"
                                                            (mousedown)="cancelUpdateOrderNotes('currentOwner')"><mat-icon>close</mat-icon></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="notes-body">
                                                <div fxFlex>
                                                    <textarea placeholder="Add Current Owner..."
                                                        (blur)="showConfirmCurrentOwner = false"
                                                        (focus)="showConfirmCurrentOwner = true" spellcheck="false"
                                                        [formControl]="currentOwner" [value]="currentOwner.value"
                                                        class="notes-body-input custom-scroll-bar"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="admin === '1'" class="pharmacy-instructions mm-mt-3">
                                    <div fxLayout="column" fxLayoutAlign="start center" class="inner"
                                        [ngClass]="{'editing': showConfirmAdminNotes}">
                                        <div class="notes-inner-container" fxLayout="column">
                                            <div class="notes-header">
                                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                                    <span>Admin Notes</span>
                                                    <div *ngIf="showConfirmAdminNotes == true">
                                                        <button class="confirm-note-button"
                                                            (mousedown)="_updateOrderNotes('adminNotes');"><mat-icon>check</mat-icon></button>
                                                        <button class="confirm-note-button mm-ml-2"
                                                            (mousedown)="cancelUpdateOrderNotes('adminNotes')"><mat-icon>close</mat-icon></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="notes-body">
                                                <div fxFlex>
                                                    <textarea placeholder="Type Admin Notes Here"
                                                        (blur)="showConfirmAdminNotes = false"
                                                        (focus)="showConfirmAdminNotes = true" spellcheck="false"
                                                        [formControl]="adminNotes" [value]="adminNotes.value"
                                                        class="notes-body-input custom-scroll-bar"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!isDriverUser" class="pharmacy-instructions mm-mt-3">
                                    <div fxLayout="column" fxLayoutAlign="start center" class="inner"
                                        [ngClass]="{'editing': showConfirmPharmacyNotes}">
                                        <div class="notes-inner-container" fxLayout="column">
                                            <div class="notes-header">
                                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                                    <div fxLayout="column">
                                                        <span>{{jwtAuth.isDoctor()?"Doctor Notes" : "Pharmacy
                                                            Notes"}}</span>
                                                        <span style="font-size:12px">Use this area to interact with the
                                                            support team.</span>
                                                    </div>

                                                    <div *ngIf="showConfirmPharmacyNotes == true">
                                                        <!-- <button class="confirm-note-button"
                                                        (mousedown)="_updateOrderNotes('pharmacyOrderNotes');"><mat-icon>check</mat-icon></button> -->
                                                        <button class="confirm-note-button mm-ml-2"
                                                            (mousedown)="cancelUpdateOrderNotes('pharmacyOrderNotes')"><mat-icon>close</mat-icon></button>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="notes-body">
                                                <div fxFlex>
                                                    <textarea
                                                        [placeholder]="admin === '1' ? 'Interact with ' + serviceScript?.orderData.locationname : 'Interact with support team'"
                                                        (blur)="showConfirmPharmacyNotes = false"
                                                        (focus)="showConfirmPharmacyNotes = true" spellcheck="false"
                                                        [formControl]="pharmacyOrderNotes"
                                                        [value]="pharmacyOrderNotes.value" readOnly="true"
                                                        class="notes-body-input custom-scroll-bar"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="end center" class="mm-mt-2">
                                        <button mat-flat-button color="primary"
                                            (click)="showAddNoteDialog(pharmacyOrderNotes.value)">Add
                                            Note</button>
                                    </div>
                                </div>

                            </div>
                        </mat-card>


                        <mat-card *ngIf="serviceScript?.originalscriptslist.length>0" fxLayoutAlign="end end">
                            <a routerLink='./' class="mm-text-accent mr-0 mm-link" (click)="openDialog()">Show Order
                                Revision</a>
                        </mat-card>

                        <mat-card *ngIf="scripts.length>0">
                            <h3 #itemEditTable>Scripts</h3>
                            <table mat-table [dataSource]="scripts" class="mat-elevation-z1 items-table">

                                <ng-container matColumnDef="Image">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="tooltip">
                                            <img *ngIf="element.prescriptiontype == 3; else notPaperScript"
                                                src="assets/images/prescription.svg" alt="Prescription icon"
                                                style="height: 40px;width: 40px;">
                                            <ng-template #notPaperScript>
                                                <img (click)='getUID("EAN", element.EAN)' src="assets/images/qrcode.png"
                                                    style="height: 40px;width: 40px;" />
                                            </ng-template>
                                            <span class="tooltiptext">Copy EAN</span>
                                        </div>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="ItemDetails">
                                    <th mat-header-cell *matHeaderCellDef> Script Details </th>
                                    <td mat-cell *matCellDef="let element; let i=index;"
                                        (click)="$event.stopPropagation()">
                                        <div
                                            *ngIf="editshow==1 && !isTelehealthOrder() && !isInStore(element.payment_status)">
                                            <input class="mm-text-input" [(ngModel)]="scripts[i].drugname">
                                        </div>
                                        <div
                                            *ngIf="editshow==0 || isTelehealthOrder() || isInStore(element.payment_status)">
                                            <div>{{element.drugname}}</div>
                                            <div *ngIf="getEntitlement(element.cardtype)"
                                                class="mm-badge mm-mb-3 mm-b-2">{{
                                                getEntitlement(element.cardtype) }}</div>
                                        </div>
                                        <div>
                                            <a class="mm-text-accent mr-10 mm-link" (click)="viewScript(element)"
                                                ngxPrint>View Script</a>
                                            <span *ngIf="serviceScript?.orderData?.medviewFlowStoreID"
                                                class="mm-text-accent mr-10 mm-link"
                                                (click)="Dispense(element)">Send&nbsp;to&nbsp;MedView&nbsp;Flow</span>
                                            <span class="mm-text-accent mr-10 mm-link"
                                                (click)="printEScript(element.script_url)">Print</span>
                                            <span *ngIf="element?.selected_variants.length"
                                                class="mm-text-accent mr-10 mm-link"
                                                (click)="open_variants_dialog(element.variants_type, element.selected_variants)">{{
                                                element.variants_type }}</span>
                                        </div>
                                    </td>
                                </ng-container>

                                <!-- <ng-container matColumnDef="UnitPrice">

                                <th mat-header-cell *matHeaderCellDef> Unit Price </th>

                                <td mat-cell *matCellDef="let element; let i=index;">
                                    <div *ngIf="editshow==1">
                                        <input class="mm-text-input" style="width:80px"
                                            [(ngModel)]="scripts[i].originalprice">
                                    </div>
                                    <div *ngIf="editshow==0">
                                        {{element.originalprice | currency}}
                                    </div>
                                </td>

                            </ng-container>

                            <ng-container matColumnDef="QTY">
                                <th mat-header-cell *matHeaderCellDef> QTY </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.drugpack?element.drugpack:'1'}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Amount">
                                <th mat-header-cell *matHeaderCellDef> Price </th>
                                <td mat-cell *matCellDef="let element"> {{getTotalPrice(element) | currency }} </td>
                            </ng-container> -->

                                <ng-container matColumnDef="PharmacyUnitPrice">

                                    <th mat-header-cell *matHeaderCellDef>
                                        <div class="markup-amount-hold">
                                            <span>My Unit Price</span>
                                            <mat-icon class="mm-text-primary my-price-info-icon"
                                                style="cursor: pointer;" (click)="openMyPriceDialog()">info</mat-icon>
                                        </div>
                                    </th>

                                    <td mat-cell *matCellDef="let element; let i = index;">

                                        <input *ngIf="!isInStore(element.payment_status)" [disabled]="uberOrder"
                                            class="mm-text-input" type="number" min="0" style="width:80px"
                                            [(ngModel)]="element.pharmacyUnitPrice">

                                        <div *ngIf="isInStore(element.payment_status)">{{ element.pharmacyUnitPrice |
                                            currency}}</div>

                                    </td>

                                </ng-container>

                                <ng-container matColumnDef="UnitPrice">

                                    <th mat-header-cell *matHeaderCellDef>
                                        <div class="markup-amount-hold">
                                            <span>Customer Price</span>
                                            <mat-icon class="mm-text-primary my-price-info-icon"
                                                style="cursor: pointer;" (click)="openMarkupDialog()">info</mat-icon>
                                        </div>
                                    </th>

                                    <td mat-cell *matCellDef="let element; let i = index;">
                                        <div>{{ element.originalprice | currency}}</div>
                                    </td>

                                </ng-container>

                                <ng-container matColumnDef="QTY">
                                    <th mat-header-cell *matHeaderCellDef> QTY </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div>{{element.drugpack ? element.drugpack : '1'}}</div>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="Amount">
                                    <th mat-header-cell *matHeaderCellDef> Amount </th>
                                    <td mat-cell *matCellDef="let element">{{calculateTotalPrice(element) | currency }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="Action">
                                    <th mat-header-cell *matHeaderCellDef> Action </th>
                                    <td mat-cell *matCellDef="let element" class="action-row">
                                        <button *ngIf="!isTelehealthOrder() && !isInStore(element.payment_status)"
                                            [disabled]="scripts.length ==1 && items.length==0"
                                            (click)="deleteScript(element.orderbasketid)" mat-icon-button>
                                            <mat-icon
                                                [ngStyle]="{'color':scripts.length==1 && items.length==0?'gray':'#0a2756'}">delete</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="scriptColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: scriptColumns;"></tr>
                            </table>
                        </mat-card>

                        <mat-card *ngIf="items.length>0 && !isDriverUser">
                            <h3 #itemEditTable>Items</h3>
                            <table mat-table [dataSource]="items" class="mat-elevation-z1 items-table">
                                <ng-container matColumnDef="Image">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class='tooltip'>
                                            <img (click)='getUID("EAN", element.EAN)' src="{{element.itemimage}}"
                                                style="height: 40px;width: 40px;" />
                                            <span class="tooltiptext">Copy EAN</span>
                                        </div>
                                        <!-- <img (click)='getUID(element.)' src="{{element.itemimage}}" style="height: 40px;width: 40px;" /> -->
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="ItemDetails">
                                    <th mat-header-cell *matHeaderCellDef> Item Details </th>
                                    <td mat-cell *matCellDef="let element; let i = index;">
                                        <div *ngIf="editshow==1&&!uberOrder&&!isTelehealthOrder() && !isInStore(element.payment_status)"
                                            class="table-cell-value">
                                            <input class="mm-text-input" [(ngModel)]="items[i].drugname">
                                        </div>
                                        <!-- <div *ngIf="editshow==1 && serviceScript.orderData.deliverypartnername!='Uber Eats'" class="table-cell-value">{{element.drugname}}</div> -->
                                        <div *ngIf="editshow==0||(editshow==1&&uberOrder)||(editshow==1&&!uberOrder&&isTelehealthOrder()) || isInStore(element.payment_status)"
                                            class="table-cell-value">{{element.drugname}}</div>
                                        <div>
                                            <span *ngIf="gen_cert_allow(element)" class="mm-text-accent mr-10 mm-link"
                                                (click)="open_med_cert_dialog(element)">Generate&nbsp;Medical&nbsp;Certificate</span>
                                            <span *ngIf="element.cert_link" class="mm-text-accent mr-10 mm-link"
                                                (click)="view_cert(element.cert_link)">View&nbsp;Certificate</span>
                                        </div>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="PharmacyUnitPrice">

                                    <th mat-header-cell *matHeaderCellDef>
                                        <div class="markup-amount-hold">
                                            <span>My Unit Price</span>
                                            <mat-icon class="mm-text-primary my-price-info-icon"
                                                style="cursor: pointer;" (click)="openMyPriceDialog()">info</mat-icon>
                                        </div>
                                    </th>

                                    <td mat-cell *matCellDef="let element; let i = index;">

                                        <input *ngIf="!isTelehealthOrder() && !isInStore(element.payment_status)"
                                            [disabled]="uberOrder" class="mm-text-input" type="number" min="0"
                                            style="width:80px" [(ngModel)]="element.pharmacyUnitPrice">
                                        <div *ngIf="isTelehealthOrder() || isInStore(element.payment_status)">
                                            {{element.pharmacyUnitPrice | currency}}</div>

                                    </td>

                                </ng-container>

                                <ng-container matColumnDef="UnitPrice">

                                    <th mat-header-cell *matHeaderCellDef>
                                        <div class="markup-amount-hold">
                                            <span>Customer Price</span>
                                            <mat-icon class="mm-text-primary my-price-info-icon"
                                                style="cursor: pointer;" (click)="openMarkupDialog()">info</mat-icon>
                                        </div>
                                    </th>

                                    <td mat-cell *matCellDef="let element; let i = index;">
                                        <div>{{ calculateCustomerPrice(element) | currency}}</div>
                                    </td>

                                </ng-container>

                                <ng-container matColumnDef="QTY">
                                    <th mat-header-cell *matHeaderCellDef> QTY </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div *ngIf="!isTelehealthOrder() && !isInStore(element.payment_status)">
                                            <button *ngIf="editshow==1"
                                                [disabled]="element.drugpack == 1 || element.drugpack == '' || element.drugpack === null"
                                                color="primary"
                                                (click)="onItemDataChangeQty(element.orderbasketid,'-'); addToItemsEdited(element)"
                                                mat-icon-button>
                                                <mat-icon>remove_circle</mat-icon>
                                            </button>
                                            {{element.drugpack?element.drugpack:'1'}}
                                            <button *ngIf="editshow==1" [disabled]='uberOrder' color="primary"
                                                (click)="onItemDataChangeQty(element.orderbasketid,'+'); addToItemsEdited1(element)"
                                                mat-icon-button>
                                                <mat-icon>add_circle</mat-icon>
                                            </button>
                                        </div>
                                        <div *ngIf="isTelehealthOrder() || isInStore(element.payment_status)">
                                            {{element.drugpack?element.drugpack:'1'}}
                                        </div>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="Amount">
                                    <th mat-header-cell *matHeaderCellDef> Amount </th>
                                    <td mat-cell *matCellDef="let element">{{calculateTotalPrice(element) | currency }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="Action">
                                    <th mat-header-cell *matHeaderCellDef> Action </th>
                                    <td mat-cell *matCellDef="let element" class="action-row">
                                        <button *ngIf="!isTelehealthOrder() && !isInStore(element.payment_status)"
                                            [disabled]="items.length === 1 && scripts.length === 0" color="primary"
                                            (click)="deleteItem(element.orderbasketid); addToItemsEdited(element);"
                                            mat-icon-button>
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>

                        </mat-card>

                        <mat-card *ngIf="!isTelehealthOrder()" class="mm-pt-0 mm-mt-0">
                            <div (click)="addItems = 1" *ngIf="editshow === 1 && !uberOrder" class="outline"
                                style="margin-top:20px">

                                <button *ngIf="addItems !== 1" mat-icon-button>
                                    <mat-icon [ngStyle]="{'color':'lightgray','font-size':'40px'}">add</mat-icon>
                                </button>

                                <div *ngIf="addItems === 1">
                                    <input matInput #otcSearchInput value="{{searchWord}}" [matAutocomplete]="otcItems"
                                        [formControl]="otcItemSearch" class="item-search-input"
                                        placeholder="Search Items...">
                                    <mat-autocomplete [autoActiveFirstOption]="true"
                                        (optionSelected)="onClickAddItem($event)" #otcItems="matAutocomplete"
                                        class="custom-scroll-bar">
                                        <mat-progress-bar *ngIf="searchingItem" mode="indeterminate"></mat-progress-bar>
                                        <mat-option [value]="otcItemSearch.value"><span>Add New Item</span> ({{
                                            otcItemSearch.value }})</mat-option>
                                        <mat-option *ngFor="let state of filteredOtcItemResults | async"
                                            [value]="state">
                                            <img class="example-option-img" aria-hidden [src]="state.PhotoID_s3"
                                                height="25">
                                            <span>{{state.drug}}</span> |
                                            <small>Price: {{state.price}}</small>
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                        </mat-card>
                        <ng-container *ngIf="canShowDocuments()">
                            <app-order-documents [orderId]="serviceScript.orderData.orderid"
                                [userId]="serviceScript.orderData.enduserid"
                            (medicalCertificateExists)="onMedicalCertificateCheck($event)"
                            ></app-order-documents>
                        </ng-container>
                        <mat-card *ngIf="!(jwtAuth.isDriver() && jwtAuth.isDoctor())">
                            <div fxLayout="row" fxLayoutAlign="end start">
                                <div fxFlex="20">
                                    <div *ngIf="!jwtAuth.isDoctor()">
                                        <div fxLayout="row" fxLayoutAlign="space-between start">
                                            <div style="font-weight:600;">Sub Total</div>
                                            <div>${{ calculateSubTotal() }}</div>
                                        </div>
                                        <div fxLayout="row" fxLayoutAlign="space-between start" class="mm-mt-3">
                                            <div style="font-weight:600;">Delivery Fee</div>

                                            <input [disabled]='uberOrder'
                                                *ngIf="editshow === 1 && admin === '1'; else deliveryFeeInput"
                                                type="number" min="0" class="mm-text-input fee-edit-input"
                                                [(ngModel)]="newDeliveryFee">


                                            <ng-template #deliveryFeeInput>
                                                <div>${{ serviceScript.orderData.deliveryfee || '0.00' }}</div>
                                            </ng-template>
                                        </div>
                                        <div fxLayout="row" fxLayoutAlign="space-between start" class="mm-mt-3">
                                            <div style="font-weight:600;">Service Fee</div>


                                            <input [disabled]='uberOrder'
                                                *ngIf="editshow === 1 && admin === '1'; else serviceFee" type="number"
                                                min="0" class="mm-text-input fee-edit-input"
                                                [(ngModel)]="newServiceFee">


                                            <ng-template #serviceFee>
                                                <div>${{ serviceScript.orderData.customerServiceFee || '0.00' }}</div>
                                            </ng-template>
                                        </div>
                                        <div fxLayout="row" fxLayoutAlign="space-between start" class="mm-mt-3">
                                            <div style="font-weight:600;">Order Total</div>
                                            <div>${{ calculateOrderTotal() }}</div>
                                            <!-- <div>${{ newOrderTotal==''?serviceScript.orderData.orderTotal: newOrderTotal }}</div> -->
                                        </div>
                                        <div
                                            *ngIf="serviceScript?.orderData.orderBalance && serviceScript?.orderData.orderBalance > 0">
                                            <div fxLayout="row" fxLayoutAlign="space-between start" class="mm-mt-3">
                                                <div style="font-weight:600;">Paid</div>
                                                <div>${{ serviceScript.orderData.orderPaidAmount }}</div>
                                            </div>
                                            <div fxLayout="row" fxLayoutAlign="space-between start" class="mm-mt-3">
                                                <div style="font-weight:600;">Redemption Amount</div>
                                                <div>${{ serviceScript.orderData.redemption_amount || '0.00' }}</div>
                                            </div>
                                            <div fxLayout="row" fxLayoutAlign="end">---------------</div>
                                            <div fxLayout="row" fxLayoutAlign="space-between start" class="mm-mt-3">
                                                <div style="font-weight:600;" class="mm-text-pink">Balance Owing</div>
                                                <div class="mm-text-pink">${{ serviceScript.orderData.orderBalance }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-card>
                        <div>
                            <mat-card *ngIf="editshow == 1 && (items.length || scripts.length)" fxLayout="row"
                                fxLayoutAlign="end center">
                                <div fxLayout="row" fxLayoutAlign="end center">
                                    <button mat-flat-button class="mm-bg-orange mm-text-white"
                                        (click)="openStockInfoDialog()">Save</button>
                                    <button mat-flat-button class="mm-bg-grey-blue mm-text-white" (click)="cancelItem()"
                                        style="margin-left:6px">Cancel</button>
                                </div>
                            </mat-card>
                        </div>
                    </div>
                </mat-card>
            </div>

        </mat-tab>
        <mat-tab *ngIf="!isDriverUser" label="Customer Profile">
            <ng-template matTabContent>
                <app-user-profile [userId]="serviceScript.orderData.enduserid"></app-user-profile>
            </ng-template>
        </mat-tab>

        <mat-tab *ngIf="!isDriverUser && !jwtAuth.isDoctor()" label="Customer Order History">
            <div class="example-large-box mat-elevation-z4" style="margin-top: 20px;">


                <table class="table-class orders-table" mat-table [dataSource]="orderHistory" matSort>
                    <ng-container matColumnDef="createddate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
                        <td mat-cell *matCellDef="let element" class="row-class">
                            <span class="mobile-label"></span> {{element.created_at| date: 'dd/MM/yyyy h:mm:ss a' }}
                            <!-- PH NAME -->
                            <div class="mt-2" *ngIf="element.locationname==serviceScript.orderData.locationname">
                                {{element.locationname}}</div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="orderid">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Unique Order ID </th>
                        <td mat-cell *matCellDef="let element" class="row-class"><span class="mobile-label"></span>
                            {{element.uniqueorderid}}
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="totalStatus">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Total </th>
                        <td mat-cell *matCellDef="let element" class="row-class">
                            <span class="mobile-label"></span> {{element.orderTotal |
                            currency}}
                            <p id='payment-info'
                                [ngStyle]="{color:element.paymentstatus==='1'? '#7fe97f': element.paymentstatus==='0'? '#ff3d57': 'black'}">
                                {{paymentStatuses[element.paymentstatus]}}</p>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="ordertype">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
                        <td mat-cell *matCellDef="let element">
                            <span class="mobile-label"></span>{{element.deliverypartnername}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="orderstatus">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                        <td mat-cell *matCellDef="let element">
                            <span class="mobile-label"></span>
                            <span
                                [ngStyle]="{color:element.orderStatus==='New'?'#0081ff':'#ff8a48'}">{{element.orderstatus=='Waiting
                                For Review'? 'Quote Request': element.orderstatus=='Awaiting Authorisation'? 'Awaiting
                                Payment': element.orderstatus}}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef> Action </th>
                        <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()" class="action-row">
                            <span class="mobile-label"></span>
                            <button
                                *ngIf='admin=="1"||element.locationname == this.serviceScript.orderData.locationname'
                                mat-icon-button>
                                <mat-icon class="mm-text-accent" (click)="goToOrder(element.orderid)">launch</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns1;"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns1;" (click)="viewOrder(row.orderid)"></tr>

                </table>

                <mat-paginator [pageSizeOptions]="[10, 20, 30, 40]" showFirstLastButtons></mat-paginator>
            </div>
        </mat-tab>
    </mat-tab-group>
</mat-card>
<mat-spinner *ngIf="loading" diameter="50"></mat-spinner>