import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-out-of-scope',
  templateUrl: './dialog-out-of-scope.component.html',
  styleUrls: ['./dialog-out-of-scope.component.scss']
})
export class DialogOutOfScopeComponent {

  options: string[] = ['Shift Ending', 'Out of Scope of Practice' , 'Called Not Answered'];
  secondaryOptions: string[] = ['Reschedule Patient', 'Next Patient'];
  showSecondaryOptions = false;
  showTextArea = false;
  otherText = '';
  selectedValue: string | null = null;
  selectedSecondaryValue: string | null = null;
  @Output() dataSelected = new EventEmitter<string>();
  constructor(public dialogRef: MatDialogRef<DialogOutOfScopeComponent>) {}

  onSelectionChange(selectedValue: string) {
    this.selectedValue = selectedValue;
    this.showTextArea = selectedValue === 'Other';
    this.showSecondaryOptions = selectedValue === 'Called Not Answered';
    this.selectedSecondaryValue = null;
  }

  onSecondaryOptionChange(selectedSecondaryValue: string) {
    this.selectedSecondaryValue = selectedSecondaryValue;
  }

  closeDialog() {
    let returnValue: string;
    if (this.showTextArea) {
      returnValue = this.otherText;
    } else if (this.showSecondaryOptions) {
      returnValue = `${this.selectedValue} - ${this.selectedSecondaryValue}`;
    } else {
      returnValue = this.selectedValue!;
    }
    this.dataSelected.emit(returnValue);
    this.dialogRef.close(returnValue);
  }

  canSubmit(): boolean {
    if (this.showTextArea) {
      return this.otherText.trim() !== '';
    }
    if (this.showSecondaryOptions) {
      return !!this.selectedSecondaryValue;
    }
    return !!this.selectedValue;
  }
  

}
