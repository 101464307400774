<div>
    <button *ngIf="buttonText" mat-flat-button class="mm-mt-3 mm-mr-2 mm-bg-green mm-text-white"
        (click)="changeShiftStatus()">
        {{ buttonText }}
    </button>

    <button *ngIf="isCompleted" mat-flat-button class="mm-mt-3 mm-bg-primary mm-text-white" (click)="onCompleteShift()"
        style="margin-left:16px">
        Complete
    </button>
</div>